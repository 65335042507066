import {
  DataModel, DataModelBuilder, Discoverable, MapUtils, SerializationContext,
  SerializedData, cloneMap, isSameData, isSameList, isSameMap, mapToData, Language,
  cloneList, toEnumFromString, fromEnumAsString, isNullOrUndefined, DiscoverSEO, VersionRange
} from 'in-time-core';
import { DiscoverCategory } from './discover-category';

function convertFromLanguageMap(map: Map<Language, string>): SerializedData {
  return mapToData(map, (k, v) => ({ key: fromEnumAsString(k), value: v }));
}

function convertToLanguageMap(data: SerializedData, key: string): Map<Language, string> {
  return MapUtils.getMap(data, key, (k, v) => ({ key: toEnumFromString(k, Language), value: v as string }));
}

export class DiscoverSnapshot extends DataModel {
  static readonly TYPE_ID: string = 'ab3eb604058d4881ab21b636f033ea91';
  static readonly SCHEMA_VERSION: number = 2;

  constructor(
    public readonly uniqueId: string,
    public readonly webRoute: string,
    public readonly isFestival: boolean,
    public readonly referralLink: string | null,
    public readonly seoTags: DiscoverSEO | null,
    public readonly images: string[],
    public readonly allCategories: DiscoverCategory[],
    public readonly featuredCategories: DiscoverCategory[],
    public readonly businesses: Map<string, Discoverable>,
    private readonly title: Map<Language, string>,
    private readonly description: Map<Language, string>,
    private readonly location: Map<Language, string>,
  ) {
    super();
  }

  get $typeId(): string {
    return DiscoverSnapshot.TYPE_ID;
  }

  override get $schemaVersion(): VersionRange {
    return VersionRange.between(1, DiscoverSnapshot.SCHEMA_VERSION);
  }

  static fromMap(context: SerializationContext, map: SerializedData): DiscoverSnapshot {
    const seoData = MapUtils.getOrNull<SerializedData>(map, 'seoTags');

    return new DiscoverSnapshot(
      MapUtils.get<string>(map, 'uniqueId'),
      MapUtils.get<string>(map, 'webRoute'),
      MapUtils.get<boolean>(map, 'isFestival'),
      MapUtils.getOrNull<string>(map, 'referralLink'),
      isNullOrUndefined(seoData) ? null : DiscoverSEO.fromMap(context, seoData),
      MapUtils.getList<string>(map, 'images', (v) => v as string),
      MapUtils.getList<DiscoverCategory>(
        map,
        'allCategories',
        (e) => context.fromData(e as SerializedData)
      ),
      MapUtils.getList<DiscoverCategory>(
        map,
        'featuredCategories',
        (e) => context.fromData(e as SerializedData)
      ),
      MapUtils.getMap<string, Discoverable>(
        map,
        'businesses',
        (key: string, value: unknown) => {
          return {
            'key': key,
            'value': context.fromData(value as SerializedData)
          };
        },
      ),
      convertToLanguageMap(map, 'title'),
      convertToLanguageMap(map, 'description'),
      convertToLanguageMap(map, 'location'),
    );
  }

  toMap(context: SerializationContext): SerializedData {
    return {
      'uniqueId': this.uniqueId,
      'webRoute': this.webRoute,
      'isFestival': this.isFestival,
      'referralLink': this.referralLink,
      'seoTags': this.seoTags?.toMap(context) ?? null,
      'images': this.images,
      'title': convertFromLanguageMap(this.title),
      'description': convertFromLanguageMap(this.description),
      'location': convertFromLanguageMap(this.location),
      'allCategories': this.allCategories.map((e) => context.toData(e)),
      'featuredCategories': this.featuredCategories.map((e) => context.toData(e)),
      'businesses': mapToData<string, Discoverable>(
        this.businesses,
        (key: string, value: Discoverable) => {
          return {
            'key': key,
            'value': context.toData(value)
          };
        }
      ),
    };
  }

  clone(): this {
    return new DiscoverSnapshot(
      this.uniqueId,
      this.webRoute,
      this.isFestival,
      this.referralLink,
      this.seoTags?.clone() ?? null,
      cloneList(this.images),
      this.allCategories.map((e) => e.clone()),
      this.featuredCategories.map((e) => e.clone()),
      cloneMap(this.businesses, (e) => e.clone()),
      cloneMap(this.title),
      cloneMap(this.description),
      cloneMap(this.location),
    ) as this;
  }

  isSame(other: this): boolean {
    if(other === this) {
      return true;
    }

    return other.uniqueId === this.uniqueId &&
        other.webRoute === this.webRoute &&
        other.isFestival === this.isFestival &&
        other.referralLink === this.referralLink &&
        isSameData(other.seoTags, this.seoTags) &&
        isSameList(other.images, this.images) &&
        isSameMap(other.title, this.title) &&
        isSameMap(other.description, this.description) &&
        isSameMap(other.location, this.location) &&
        isSameList(other.allCategories, this.allCategories, isSameData) &&
        isSameList(other.featuredCategories, this.featuredCategories, isSameData) &&
        isSameMap(other.businesses, this.businesses, isSameData);
  }

  getTitleOrFirst(language: Language): string | null {
    return this.getFirstAvailableLocalizedString(this.title, language);
  }

  getDescriptionOrFirst(language: Language): string | null {
    return this.getFirstAvailableLocalizedString(this.description, language);
  }

  getLocationOrFirst(language: Language): string | null {
    return this.getFirstAvailableLocalizedString(this.location, language);
  }

  private getFirstAvailableLocalizedString(map: Map<Language, string>, desiredLanguage: Language): string | null {
    let value: string | null = map.get(desiredLanguage) ?? map.get(Language.English) ?? null;
    if(isNullOrUndefined(value) && map.size > 0) {
      value = map.values().next().value;
    }

    return value;
  }
}

export class DiscoverSnapshotBuilder extends DataModelBuilder<DiscoverSnapshot> {
  build(context: SerializationContext, map: SerializedData): DiscoverSnapshot {
    return DiscoverSnapshot.fromMap(context, map);
  }

  override migrateToCurrentSchemaVersion(context: SerializationContext, map: SerializedData): SerializedData | null {
    const versionRange = MapUtils.getVersionRange(map);
    if(versionRange.contains(DiscoverSnapshot.SCHEMA_VERSION)) {
      return map;
    }
    if(versionRange.isGreaterThan(DiscoverSnapshot.SCHEMA_VERSION)) {
      return null; // This data comes from the FUTURE. We have no idea how to migrate it.
    }

    let version = versionRange.to;

    // In this version `seoTags` of type [DiscoverSEO] was introduced.
    if(version < 2) {
      map['seoTags'] = null;
      version = 2;
    }

    return map;
  }
}
