import {
  DataModel, DataModelBuilder, Language, MapUtils, SerializationContext, SerializedData,
  cloneList, cloneMap, convertFromLanguageMap, convertToLanguageMap, getAnyFromLocalizationMap, isSameList, isSameMap,
} from 'in-time-core';

export class DiscoverCategory extends DataModel {
  static readonly TYPE_ID: string = 'c6c6326c1cbe4ab7b23d138af76ed379';

  constructor(
    public readonly uniqueId: string,
    public readonly icon: string | null,
    public readonly name: Map<Language, string>,
    public readonly rank: number,
    public readonly filterOrder: number,
    public readonly pointsOfInterest: string[],
  ) { super(); }

  get $typeId(): string {
    return DiscoverCategory.TYPE_ID;
  }

  static fromMap(
    context: SerializationContext,
    map: SerializedData
  ): DiscoverCategory {
    return new DiscoverCategory(
      MapUtils.get<string>(map, 'uniqueId'),
      MapUtils.getOrNull<string>(map, 'icon'),
      convertToLanguageMap(map, 'name'),
      MapUtils.get<number>(map, 'rank'),
      MapUtils.get<number>(map, 'filterOrder'),
      MapUtils.getList<string>(map, 'pointsOfInterest', (e) => e as string),
    );
  }

  toMap(context: SerializationContext): SerializedData {
    return {
      'uniqueId': this.uniqueId,
      'name': convertFromLanguageMap(this.name),
      'rank': this.rank,
      'filterOrder': this.filterOrder,
      'pointsOfInterest': this.pointsOfInterest,
    };
  }

  clone(): this {
    return new DiscoverCategory(
      this.uniqueId,
      this.icon,
      cloneMap(this.name),
      this.rank,
      this.filterOrder,
      cloneList(this.pointsOfInterest),
    ) as this;
  }

  isSame(other: this): boolean {
    if(other === this) {
      return true;
    }

    return other.uniqueId === this.uniqueId &&
        other.icon === this.icon &&
        other.rank === this.rank &&
        other.filterOrder === this.filterOrder &&
        isSameMap(other.name, this.name) &&
        isSameList(other.pointsOfInterest, this.pointsOfInterest)
  }

  getName(language: Language): string {
    return getAnyFromLocalizationMap(this.name, language, (v) => v.length > 0) ?? this.uniqueId;
  }
}

export class DiscoverCategoryBuilder extends DataModelBuilder<DiscoverCategory> {
  build(context: SerializationContext, map: SerializedData): DiscoverCategory {
    return DiscoverCategory.fromMap(context, map);
  }
}
